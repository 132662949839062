import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import strings from '../../utils/strings';
import FinishSound from '../../components/FinishSound';

import {updateObject} from '../../utils/reducerUtils';

import Countdown from '../../components/Countdown';

import {VARIATION} from '../../constants';

class Test extends React.Component {
	constructor() {
		super();

		const results = {};
		const variation = VARIATION;
		const language = strings.getLanguage();
		this.assignments = require(`../../assets/testdata/${variation}/${language}/tulleord-data.json`);
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/SCR058.mp3`)
				: null;
		for (const assignment of this.assignments) {
			results[assignment.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
			renderFinalSound: false,
		};

		this.audioRefs = {};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {results, timeRanOut} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		let filteredAssignments;

		if (timeRanOut) {
			filteredAssignments = this.assignments.filter((assignment) => {
				return results[assignment.id];
			});
		} else {
			filteredAssignments = this.assignments;
		}

		return (
			<div className="lit-test">
				<div className="lit-infobar">
					<Countdown
						duration={120}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
								renderFinalSound: true,
							});
						}}
					/>
				</div>
				{!this.state.renderFinalSound && (
					<p>{strings.tulleordInstruksjon}</p>
				)}
				<div className="lit-tulleord">
					{filteredAssignments.map((assignment) => {
						const {id, value} = assignment;

						const words = value.split(' ');

						const currentAssignmentValue = results[id];

						return (
							<div className="lit-words" key={id}>
								<span className="lit-input__label lit-input__label--count">
									{id}
								</span>
								<div className="lit-word-list">
									{words.map((word) => {
										const idWord = id + word;
										return (
											<span
												className={classNames({
													'lit-word': true,
													disabled: timeRanOut,
												})}
												key={idWord}
											>
												<input
													checked={
														currentAssignmentValue ===
														word
													}
													className="lit-word__field"
													disabled={timeRanOut}
													id={idWord}
													onChange={() =>
														this.setResults(
															id,
															word
														)
													}
													type="radio"
												/>
												<label
													className={classNames({
														'lit-word__label': true,
														disabled: timeRanOut,
													})}
													htmlFor={idWord}
												>
													<div className="checkbox" />
													{word}
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{(isNextButtonVisible || this.state.renderFinalSound) && (
					<div>
						<span>
							<br></br>
							<FinishSound
								renderFinalSound={null}
								text={strings.Homonyms_is_Finished}
								audioSrc={this.taskIsOver}
							/>

							<button
								className="lit-btn lit bg-primary"
								onClick={() => onNextStep(results)}
							>
								{strings.done}
							</button>
						</span>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);

import audio2 from '../../../../assets/sounds/youth/de/2 3 Ordkjedetest-Feil-los-tupp-meg-sa.wav';

const assignments = [
	{
		id: 1,
		text: 'PREISDORTKLEINLEBEN',
		answer: 'PREIS DORT KLEIN LEBEN',
		audio: audio2,
		/*count: 'andre',
		hasPlayed: false, */
	},
	{
		id: 2,
		text: 'GEBENMITBRUNNENREICH',
		answer: 'GEBEN MIT BRUNNEN REICH',
		audio: audio2,
		count: 'tredje',
		hasPlayed: false,
	},
];
export default assignments;

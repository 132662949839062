import React from 'react';
import {connect} from 'react-redux';

import strings from '../../utils/strings';

import {updateObject} from '../../utils/reducerUtils';
import FinishSound from '../../components/FinishSound';

import classNames from 'classnames';
import Countdown from '../../components/Countdown';

import {VARIATION} from '../../constants';

class Test extends React.Component {
	constructor() {
		super();
		const variation = VARIATION;
		const language = strings.getLanguage();

		const questions = require(`../../assets/testdata/${variation}/${language}/readingtest-questions.json`);
		const testText = require(`../../assets/testdata/${variation}/${language}/readingtest-text.js`)
			.default;
		this.taskIsOver =
			language === 'de'
				? require(`../../assets/sounds/${variation}/${language}/readingOver.mp3`)
				: null;
		this.questions = questions;
		this.testText = testText;

		const results = {};

		for (const question of this.questions) {
			results[question.id] = '';
		}
		this.state = {
			results,
			timeRanOut: false,
			renderFinalSound: false,
		};
	}

	setResults(id, value) {
		const {results} = this.state;

		this.setState({
			results: updateObject(results, {
				[id]: value,
			}),
		});
	}

	render() {
		const {onNextStep} = this.props;

		const {results, timeRanOut} = this.state;

		const allResultsHasValues = Object.keys(results).every(
			(key) => results[key]
		);
		const isNextButtonVisible = allResultsHasValues || timeRanOut;

		return (
			<div className="lit-test lit-leseproven">
				<div className="lit-infobar">
					<Countdown
						duration={720}
						onComplete={() => {
							this.setState({
								timeRanOut: true,
								renderFinalSound: true,
							});
						}}
					/>
				</div>
				<div className="story">{this.testText}</div>
				<div
					className={classNames({
						'lit-questions': true,
						'time-ran-out': timeRanOut,
					})}
				>
					<div className="questions-intro">
						<h2 className="lit-page-title">
							{strings.questions_regarding_text}
						</h2>
						<p>{strings.select_correct_option}</p>
					</div>
					{this.questions.map((question) => {
						const {id, choices} = question;

						const currentQuestionValue = results[id];

						return (
							<div className="lit-question" key={id}>
								<div className="question">
									<span className="lit-input__label lit-input__label--count">
										{id}
									</span>
									<h4 className="question__text">
										{question.question}
									</h4>
								</div>
								<div className="choices">
									{choices.map((choice) => {
										const choiceLetter = choice.letter;
										const idChoice = id + choiceLetter;
										return (
											<span
												className="lit-choice"
												key={idChoice}
											>
												<input
													checked={
														currentQuestionValue ===
														choiceLetter
													}
													className="lit-choice__field"
													disabled={timeRanOut}
													id={idChoice}
													onChange={() =>
														this.setResults(
															id,
															choiceLetter
														)
													}
													type="radio"
												/>
												<label
													className="lit-choice__label"
													htmlFor={idChoice}
												>
													<div className="checkbox" />
													{choice.choice}
												</label>
											</span>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>

				{(isNextButtonVisible || this.state.renderFinalSound) && (
					<div>
						<span>
							<FinishSound
								renderFinalSound={null}
								text={strings.Reading_essay_is_Finished}
								audioSrc={this.taskIsOver}
							/>

							<button
								className="lit-btn lit bg-primary"
								onClick={() => onNextStep(results)}
							>
								{strings.done}
							</button>
						</span>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps)(Test);

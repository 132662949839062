import React from 'react';

export const text = (
	<span>
		<h2 className="text-center">{`Vändpunkten`}</h2>
		<p>
			{`”Så, vad har du att säga till ditt försvar?” frågar pappa upprört. Jag vet inte vad jag ska säga. Jag känner en ström av ångest spridas upp i armarna och ut i käken. Jag stelnar till av rädsla och inser att jag har gjort något allvarligt fel, men jag har ingen aning om vad. Tiden står stilla, och vi stirrar alla tyst på varandra i vad som känns som en evighet.`}
		</p>
		<br/>
		<p>
			{`Mamma hade fått ett samtal från chefen på köpcentret medan jag var på väg hem. Tydligen hade en del saker stulits från en av butikerna som jag hade varit i. ”Va?! Jag har inte stulit något!” utbrister jag.`}
		</p>
		<br/>
		<p>
			{`”Kolla min väska, kolla mina kläder. Jag har inte tagit något!” Jag vet att de inte kommer att hitta något i min skolväska eller i mina fickor. Mamma tömmer ut innehållet i väskan på köksbordet medan pappa ilsket söker igenom prylarna i mina jackfickor. Mamma tar ut allt ur min sminkväska och håller upp en sak i taget. ”Varifrån fick du det här? Och detta? Och hur är det med den här?” frågar hon.`}
		</p>
		<br/>
		<p>
			{`Det är en gammal tub med läppglans. ”Mamma, kom igen! Jag köpte den för minst tre månader sedan. Ser du inte att den nästan är tom?”, svarar jag. Så här håller det på  ända tills de har gått igenom allt som finns i min sminkväska, mitt pennfodral och mina gymnastikgrejer. Inget av innehållet är nytt, och jag har kunnat redogöra för allt. Mamma och pappa utbyter blickar, och jag kan ana en viss osäkerhet hos dem.`}
		</p>
		<br/>
		<p>
			{`”Är det något du vill berätta för oss?” frågar pappa. ”Det här är din chans att berätta sanningen för oss. Om du har gjort något dumt vill vi höra det från dig och inte från någon annan.”`}
		</p>
		<br/>
		<p>
			{`”Pappa! Du kan själv se att jag inte har tagit något”, svarar jag. Pappa fortsätter. ”Chefen var mycket tydlig med att det var du som stal prylarna från butiken. Varför skulle de ringa oss om något så allvarligt om de inte verkligen trodde att det var du? Det här är ingen lek. Om du ljuger för oss nu, då har vi ett allvarligt problem.”`}
		</p>
		<br/>
		<p>
			{`Tidigare hade jag bara varit rädd, men nu blir jag också ledsen över att de inte verkar tro på mig, inte lita på mig, trots att de inte hade hittat något. Mina egna föräldrar litar mer på andra än på mig. Det här är en helt ny känsla. Jag vill gråta, men jag är så rädd och tårarna vill bara inte komma.`}
		</p>
		<br/>
		<p>
			{`”Chefen vill prata med oss, så vi åker dit nu på en gång”, säger mamma. ”Men kan vi inte äta innan vi åker?” frågar jag i ett försök att fördröja denna hemska situation. Jag är inte ens hungrig, men lukten av mat som kommer från köket ger mig hopp om att allt bara kan pausas för en stund. Jag mår illa, och inte ens spaghetti bolognese, vanligtvis min favoriträtt, känns frestande. ”Middagen får vänta”, svarar mamma. ”Vi måste reda ut den här saken så fort som möjligt.”`}
		</p>
		<br/>
		<p>
			{`Det känns konstigt att köra tillbaka samma väg som jag för mindre än en timme sedan hade åkt med buss. Jag hade bara stannat till på köpcentret för att ta en milkshake med en kompis efter skolan. Det är så tyst i bilen. Jag har hörlurarna i öronen men med låg volym så att jag kan höra om mamma och pappa pratar med varandra. Men de utbyter inte ett enda ord under hela resan. Ihopkrupen i baksätet tittar jag på mammas ansikte i sidospegeln och försöker läsa av hennes ansiktsuttryck. Jag kan se pappas ansikte i backspegeln tydligare. Han ser väldigt allvarlig och fokuserad ut när han kör i tystnad. Jag undrar om de är lättade över att de inte hittade något i min väska eller i mina fickor. Jag undrar vad de kommer att säga till chefen när vi kommer dit.`}
		</p>
		<br/>
		<p>
			{`Vi parkerar bilen och går in i köpcentret. Det är fortfarande en hektisk fredagseftermiddag, och jag kan inte låta bli att titta på alla människor som går runt, skrattar och pratar bekymmerslöst. Mamma verkar veta vart vi ska gå. Vi går fram till en grön dörr som är skyltad ”Ledning”. Jag har aldrig lagt märke till den här dörren förut, även om jag måste ha passerat den många gånger på väg till toaletterna som finns i närheten. En ordningsvakt står utanför dörren och visar oss vägen till chefens kontor. Vi går genom en smal korridor, flankerad på varje sida av massor av dörrar. I slutet av korridoren går vi genom en öppen dörr. Inne på kontoret sitter två kvinnor och ytterligare en ordningsvakt. Jag känner igen en av kvinnorna. Hon har långt blont hår, knallröda naglar och läppar och är väldigt fint klädd. Jag tror att hon är chef för köpcentret. Jag känner igen den andra kvinnan från en av butikerna. Hon är vacker och har långt brunt hår samlat i en hästsvans. Jag känner också igen ordningsvakten. Jag har sett honom gå runt i köpcentret eller sitta vid informationsdisken på första våningen.`}
		</p>
		<br/>
		<p>
			{`Vi sitter runt ett stort mötesbord, och chefen knackar sina röda naglar på bordsskivan medan hon väntar på att vi ska slå oss ner. Hon hostar och ser obekväm ut. Hon ler konstlat, och jag märker att hon har rött läppstift på tänderna. ”Det är alltid väldigt tråkigt när unga människor tas för snatteri”, säger hon. Jag ryser till och önskar att jag kunde sjunka ner och försvinna i stolen. ”Vi har en nolltoleranspolicy som innebär att vi polisanmäler alla.” Hon vänder sig mot den andra kvinnan och nickar lätt. Damen från en av butikerna tittar rakt på mamma. ”Det krossar en mammas hjärta när jag måste berätta för henne att hennes barn har stulit något”, säger hon. Jag stirrar på mina händer i mitt knä. Det här är hemskt, och jag kan inte förmå mig att lyfta blicken och ta ögonkontakt med någon. ”Jag känner deras smärta,” fortsätter hon. ”Men vad kan jag göra? Jag måste betala för lager, jag måste betala hyra för butiken och jag måste betala mina anställda. Om folk stjäl från min butik är det jag som förlorar, och jag är inte beredd att ta förlusten bara för att vissa människor inte förstår den jävla skillnaden mellan rätt och fel.” Hon är arg nu, hennes kinder är rosa kinder och händerna darrar.`}
		</p>
		<br/>
		<p>
			{`Nu börjar ordningsvakten att tala. Jag har sett honom på köpcentret i åratal, men jag har aldrig hört honom tala förut. Hans röst är förvånansvärt mild. ”Vad ni behöver förstå är att vi har mycket erfarenhet av det här, och vi vet hur man upptäcker potentiella snattare. Människor som stjäl från butiker beter sig ganska förutsägbart, även om de tycker att de beter sig helt normalt. Pojkar stjäl vanligtvis på egen hand, men tjejer brukar stjäla när de är med andra människor.” Hans lugna men bestämda röst har fångat allas uppmärksamhet, och vi tittar alla på honom. Han har ett snällt ansikte. Han fortsätter. ”Till exempel, om tre tjejer är ute och handlar tillsammans går de alla till samma butik. Två av dem distraherar personalen på något sätt, medan den tredje stjäl något. Handlingarna är vanligtvis planerade och genomtänkta.”`}
		</p>
		<br/>
		<p>
			{`Chefen nickar energiskt och tittar på mig och sedan på mina föräldrar. ”Ibland fångar vi bara en person ur en grupp vänner”, säger hon med en axelryckning. ”Men vi vet vilka de andra är, och vi övervakar dem alltid noga när de kommer tillbaka till köpcentret. Alla som umgås med en känd snattare anses skyldiga tills de har bevisats oskyldiga.” Hon lutar sig triumferande tillbaka i sin stol.`}
		</p>
		<br/>
		<p>
			{`Men jag kan se ett litet leende på min mammas läppar. Jag kan se att hon nu vet att det här inte handlar om mig. Jag är inte medlem i något gäng, och jag umgås inte med några snattare. Faktum är att jag har haft svårt att få vänner trots att vi har bott i området i nästan tre år. När vi flyttade hit ignorerade klassens drottning och hennes lilla grupp av anhängare mig hela första året, och pojkarna i klassen är omogna och sprider bara rykten om alla. Lyckligtvis har det blivit bättre på sistone, och jag har börjat få vänner. Jag planerar till och med en filmkväll med klassens drottning hemma hos mig imorgon kväll! Jag kan dock inte förneka det, jag saknar verkligen fortfarande min gamla skola och mina gamla vänner.`}
		</p>
		<br/>
		<p>
			{`Chefen sätter sig upp i sin stol och blir plötsligt allvarlig igen. ”Oavsett hur gammal du är så polisanmäler vi alltid sådana här ärenden, och vi överlåter till polisen att utreda saken vidare. De brukar ofta involvera även socialtjänsten.” Min pappa sitter nedsjunken i sin stol och ser utmattad ut. Jag vill gråta, men jag är fortfarande för rädd. Chefen för köpcentret, damen från butiken och ordningsvakten pratar inte med varandra eller tittar inte ens på varandra särskilt mycket. De turas bara om att säga mer och mer hemska saker. Det är en välregisserad föreställning, jag förstår att de har haft det här samtalet många gånger tidigare med andra föräldrar.`}
		</p>
		<br/>
		<p>
			{`Mamma sätter sig upp och förbereder sig för att säga något, men innan hon hinner få fram ett ord räcker ordningsvakten upp sin hand och stoppar henne. Han fixerar mig med sin hårda blick. ”Beroende på hur gammal du är  och vad du har tagit kan du bli åtalad. Det betyder att du kommer att finnas i brottsregistret. Eller så kanske du får en varning, vilket innebär att vi och polisen vet att du är skyldig, det är bara det att du inte kommer att straffas den här gången.” Än en gång börjar min mamma att prata, men återigen blir hon avbruten, denna gång av chefen för köpcentret. Chefen tittar på pappa, trots att det är mig hon verkligen pratar med. ”Kom ihåg att det kan allvarligt påverka dina val och din frihet om du förekommer i brottsregistret. Det kan orsaka problem när du söker jobb eller till och med när du vill resa.” Hon vänder sig om för att titta på mig, och hennes ansikte är kallt och argt. ”Hur gammal är du?”`}
		</p>
		<br/>
		<p>
			{`Pappa har fått nog. Han slår ner handen i bordet så att alla hoppar till. När han börjar att prata blir hans röst konstigt tyst och lugn, som den bara blir när han är riktigt arg. ”Nu räcker det!” säger han. ”Ni har fått fram ert budskap klart och tydligt . Men jag antar att ni alla också är medvetna om att det är straffbart att komma med falska anklagelser, fabricera bevis och skrämma ett barn?” Atmosfären i rummet skiftar, och luften vibrerar av elektricitet. Chefen börjar prata, men pappa avbryter henne. ”Vi sökte igenom hennes skolväska och hennes jackfickor så fort hon kom genom ytterdörren. Hon hade inga möjligheter att dölja någonting. Och vi hittade ingenting som hon inte kunde redogöra för. Ingenting! Så berätta gärna hur ni kommit fram till att det finns starka skäl att misstänka vår dotter när ni uppenbarligen inte har några som helst bevis?” Han sätter sig tillbaka i sin stol med glänsande ögon och ser triumferande ut. Mamma sträcker ut handen och kramar min hand och ger mig ett litet leende.`}
		</p>
		<br/>
		<p>
			{`Jag är så lättad. Mamma och pappa tror på mig. Jag är plötsligt så trött och vill bara hem och äta min middag och krypa upp i soffan med huvudet i mammas knä.`}
		</p>
		<br/>
		<p>
			{`Även om det bara är sen eftermiddag känns det som att vi har varit här i flera dagar. Jag vill bara åka hem och njuta av en lugn helg. Jag vill inte ha den här typen av drama. Precis när jag tror att allt är över och att de kommer att säga att vi kan gå säger ordningsvakten något som får hela min värld att falla samman.`}
		</p>
		<br/>
		<p>
			{`”Tyvärr har vi faktiskt bevis. Inte bara från idag, utan också från två tidigare tillfällen, i två olika butiker. Som jag sa tidigare, tjejer föredrar att stjäla i grupp, men så är inte fallet med din dotter. Vi har haft våra misstankar om henne ett tag, och nu har vi kunnat bekräfta detta genom bilder från våra övervakningskameror.”`}
		</p>
		<br/>
		<p>
			{`Min värld rasar samman. Min kropp är förlamad, och jag känner hur mitt ansikte bleknar. Mamma och pappa vänder sig om som i slow motion för att titta på mig. Sedan exploderar rummet av röster, skrik och gråt, men allt känns så långt borta. Det är som att jag inte förstår vad som händer runt omkring mig. Jag har krossat deras hjärtan genom att ljuga för dem. Jag skäms för det jag har gjort. Men framför allt är jag så lättad och så glad att jag har blivit gripen. Det är över nu.`}
		</p>
	</span>
);
export default text;

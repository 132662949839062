import React from 'react';
import {connect} from 'react-redux';
import './styles.scss';
import Logo from '../../assets/logo-white-2.png';
import {begin} from '../../utils/beforeunload';
import Introduksjon from '../../components/Introduksjon';
import Orddiktat from '../../components/Orddiktat';
import Ordkjedetesten from '../../components/Ordkjedetesten';
import Tulleordtesten from '../../components/Tulleordtesten';
import Skrivehastighet from '../../components/Skrivehastighet';
import Leseproven from '../../components/Leseproven';
import Ordforstaelse from '../../components/Ordforstaelse';
import Ferdig from '../../components/Ferdig';
import getTestInfo from '../../api/getTestInfo';
import getUserInfo from '../../api/getUserInfo';
import getUserGrade from '../../api/getUserGrade';
import setAnamneseVersion from '../../api/setAnamneseVersion';
import {setTestCandidateGrade} from '../../actions/index';

const testSteps = [
	Introduksjon,
	Orddiktat,
	Ordkjedetesten,
	Tulleordtesten,
	Skrivehastighet,
	Leseproven,
	Ordforstaelse,
	Ferdig,
];
const renderRemainingTests = [];

class ScreeningTest extends React.Component {
	constructor() {
		super();

		this.state = {
			isLoggedIn: false,
			includeAnamnese: false,
		};
	}

	componentDidMount() {
		getTestInfo().then((results) => {
			const exclusiveTests = results.exclusiveTests;
			const grade = results.grade;
			const testToContinue = Object.values(exclusiveTests);
			if (testToContinue.length) {
				for (let i = 0; i < testToContinue.length + 1; i++) {
					switch (testToContinue[i]) {
						case 1:
							renderRemainingTests.push(Orddiktat);
							break;
						case 2:
							renderRemainingTests.push(Ordkjedetesten);
							break;
						case 3:
							renderRemainingTests.push(Tulleordtesten);
							break;
						case 4:
							renderRemainingTests.push(Skrivehastighet);
							break;
						case 5:
							renderRemainingTests.push(Leseproven);

							break;
						case 6:
							renderRemainingTests.push(Ordforstaelse);

							break;
					}
					if (i === testToContinue.length) {
						renderRemainingTests.push(Ferdig);
					}
				}
				getUserGrade().then((results) => {
					this.props.onSetTestCandidateGrade(results);
				});
			} else if (grade) {
				renderRemainingTests.push(
					Orddiktat,
					Ordkjedetesten,
					Tulleordtesten,
					Skrivehastighet,
					Leseproven,
					Ordforstaelse,
					Ferdig
				);
				this.props.onSetTestCandidateGrade(grade);
			}

			setAnamneseVersion().then((response) => {
				if (response === 'True') {
					this.setState({includeAnamnese: true});
				} else {
					this.setState({includeAnamnese: false});
				}
			});

			getUserInfo().then((response) => {
				if (response.ok) {
					this.setState({
						isLoggedIn: true,
					});
					begin();
				} else {
					window.location.href = `/`;
				}
			});
		});
	}

	render() {
		const {isLoggedIn} = this.state;

		if (!isLoggedIn) {
			return null;
		}

		const {currentStepIndex, onSetTestCandidateGrade} = this.props;

		if (renderRemainingTests.length) {
			var CurrentStep = renderRemainingTests[currentStepIndex];
		} else {
			var CurrentStep = testSteps[currentStepIndex];
		}

		return (
			<div>
				<header className="lit-header">
					<h1 className="lit-logo">
						<img
							className="lit-logo__img"
							src={Logo}
							alt="literate-logo"
						/>
					</h1>
				</header>
				<div className="lit-wrapper--article text-center">
					<CurrentStep
						includeLongAnamnese={this.state.includeAnamnese}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentStepIndex} = state;

	return {
		currentStepIndex,
	};
};
const mapDispatchToProps = {
	onSetTestCandidateGrade: setTestCandidateGrade,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningTest);
